import React from 'react';
import { Container, Row } from 'react-bootstrap';
import '../Gallery.css';
import data from './galleryFiles.json'
import GalleryJobSlice from './GalleryJobSlice';
import Masonry from "react-masonry-css"
import services from './services.json'



const MasonryGrid = () => {

  const normalize = (category) => {
    if (category === "Shelving Installation") {
      return ("Utility Cabinet/Shelf Installation")
    } else if (category === "Flag Holder Installation") {
      return ("Flag Holders")
    } else if (category === "Faucet Installation") {
      return ("Miscellaneous")
    } else {
      return category
    }
  }

  const getServiceInfo = (t) => {
    const serv = services.find((s) => s.title === normalize(t))
    return ([serv.title, serv.description, serv.service_image])
  }

  const breakpointColumnsObj = {
    default: 3,
    1415: 2,
    1000: 1
  };

  return (
    <Container>
      <Row>
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className='my-masonry-grid'
          columnClassName='my-masonry-grid_column'>
          {data.map((o, index) => (
            <GalleryJobSlice
              key={index}
              title={o.job_title}
              location={o.job_location}
              category={o.job_category}
              images={o.images}
              date={o.job_date}
              orientations={o.portrait_idx}
              testimonial_idx={o.testimonial}
              s_title={getServiceInfo(o.job_category)[0]}
              s_description={getServiceInfo(o.job_category)[1]}
              s_img={getServiceInfo(o.job_category)[2]}
              repeat_customer={o.repeat_customer}
              referred_customer={o.referred_customer}
            />
          ))}
        </Masonry>
      </Row>
    </Container>
  );


};

export default MasonryGrid;
