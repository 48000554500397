import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Badge, Modal, Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import '../Gallery.css';
import testimonials from "./testimonials.json"
import Testimonial from './Testimonial';
import { useMediaQuery } from 'react-responsive'


export default function GalleryJobSlice({
    title,
    date,
    location,
    category,
    images,
    orientations,
    testimonial_idx,
    s_title,
    s_img,
    s_description,
    repeat_customer,
    referred_customer
}) {
    const [selectedImage, setSelectedImage] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const isMobile = useMediaQuery({ maxWidth: 425 })

    const [showT, setShowT] = useState(false)
    const [showS, setShowS] = useState(false)

    const [review, setReview] = useState("");
    const [name, setName] = useState("");
    const [t_date, setDate] = useState("");
    const [rating, setRating] = useState();
    const [t_location, setLocation] = useState("");

    const handleCloseT = () => setShowT(false)
    const handleShowT = () => setShowT(true)

    const handleCloseS = () => setShowS(false)

    const handleShowS = () => setShowS(true)


    const openOverlay = (imageUrl) => {
        setSelectedImage(imageUrl);
        setIsModalOpen(true);
    };


    const closeOverlay = () => {
        setIsModalOpen(false);
        handleCloseT()
        setTimeout(() => {
            setSelectedImage(null);
        }, 300);
    };

    const handleKeyDown = (event) => {
        if (selectedImage !== null) {
            if (event.key === 'ArrowLeft') {
                navigateImages(-1);
            } else if (event.key === 'ArrowRight') {
                navigateImages(1);
            }
        }
    };


    const navigateImages = (step) => {
        const currentIndex = images.indexOf(selectedImage);
        const newIndex = (currentIndex + step + images.length) % images.length;
        setSelectedImage(images[newIndex]);
    };

    const openTestimonial = () => {
        const found_testimonial = testimonials.find(t => t.id === testimonial_idx);
        setReview(found_testimonial.review);
        setName(found_testimonial.name);
        setDate(found_testimonial.date)
        setRating(found_testimonial.rating)
        setLocation(found_testimonial.location)
        handleShowT();
    };


    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [selectedImage]);



    if (!isMobile) {
        return (
            <Card className="gallery-job-slice">
                <Card.Header>
                    <Row>
                        <Col>
                            <Row>
                                <Col md="auto" className='title' style={{ whiteSpace: 'nowrap' }}>{title}</Col>
                            </Row>
                            <Row>
                                <Col md="auto" className='title-location'>{location}</Col>
                            </Row>
                        </Col>
                        <Col className='badge-col'>
                            {referred_customer && (
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="tooltip-repeat-customer">Referred Client</Tooltip>}
                                >
                                    <img width='25px' height='25px' src='./svgs/referral.svg' alt="Referred Client" />
                                </OverlayTrigger>
                            )}
                            {repeat_customer && (
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="tooltip-repeat-customer">Repeat Client</Tooltip>}
                                >
                                    <img width='25px' height='25px' src='./svgs/repeat-customer.svg' alt="Repeat Client" />
                                </OverlayTrigger>
                            )}
                            {testimonial_idx && (
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="tooltip-testimonial">Testimonial</Tooltip>}
                                >
                                    <img className="clickable" onClick={openTestimonial} width='25px' height='25px' src='./svgs/comment.svg' alt="Testimonial" />
                                </OverlayTrigger>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col md="auto" className='title-date'>{date}</Col>
                        <Col className='badge-col'>
                            <Badge className={`clickable ${category === "Miscellaneous" ? "unclickable" : ""}`} onClick={handleShowS}>
                                {category}
                            </Badge>
                        </Col>

                    </Row>
                </Card.Header>

                <Card.Body className="gallery-card" >
                    {images.map((imageUrl, index) => {
                        var cn = 'gallery-image-wrapper-landscape'

                        if (orientations.includes(index)) {
                            cn = 'gallery-image-wrapper-portrait'
                        }

                        return (
                            <div key={index} className={cn} onClick={() => openOverlay(imageUrl)}>
                                <img className="clickable" src={'./gallery_imgs/' + imageUrl} alt={`Gallery ${index}`} />
                            </div>
                        );
                    })}
                </Card.Body>

                <Modal show={isModalOpen} onHide={closeOverlay} centered dialogClassName='img-modal-dialog'>
                    <Modal.Header>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    {selectedImage && (
                        <Modal.Body>
                            <Image src={'./gallery_imgs/' + selectedImage} rounded alt="Selected Image" className="selected-image" />
                        </Modal.Body>
                    )}
                </Modal>

                <Modal show={showT} onHide={handleCloseT} centered dialogClassName='testimonial-modal-dialog' >
                    <Modal.Body style={{ margin: 'auto' }}>
                        <Testimonial name={name} date={t_date} rating={rating} description={review} location={t_location}></Testimonial>
                    </Modal.Body>
                </Modal>

                {category !== "Miscellaneous" && (
                    <Modal show={showS} onHide={handleCloseS} centered dialogClassName='img-modal-dialog'>
                        <Modal.Body style={{ margin: 'auto' }}>
                            <Card className="service-card">
                                <Card.Header>{s_title}</Card.Header>
                                <Card.Body>
                                    <Row style={{ height: '100%' }}>
                                        <Col style={{ borderRight: '1px solid #969696' }}>
                                            <Image className='service-card-image' src={s_img} />
                                        </Col>
                                        <Col>
                                            <p className='card-textbox'>{s_description}</p>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Modal.Body>
                    </Modal>
                )}



            </Card>
        );
    } else {
        return (
            <Card className="gallery-job-slice">
                <Card.Header>
                    <Row>
                        <Col><strong>{title}</strong></Col>
                    </Row>
                    <Row>
                        <Col style={{ fontSize: '0.9em', fontStyle: 'italic' }}>{location} </Col>
                        <Col style={{ fontSize: '0.9em', fontStyle: 'italic' }}>{date}</Col>
                    </Row>
                    <Row>
                        <Badge onClick={handleShowS} style={{ width: 'fit-content', margin: 'auto', marginTop: '5px' }}>{category}</Badge>
                    </Row>
                </Card.Header>

                <Card.Body className="gallery-card" >
                    {images.map((imageUrl, index) => {
                        var cn = 'gallery-image-wrapper-landscape'

                        if (orientations.includes(index)) {
                            cn = 'gallery-image-wrapper-portrait'
                        }

                        return (
                            <div key={index} className={cn} onClick={() => openOverlay(imageUrl)}>
                                <img className="gallery-img" src={'./gallery_imgs/' + imageUrl} alt={`Gallery ${index}`} />
                            </div>
                        );
                    })}
                </Card.Body>

                <Modal show={isModalOpen} onHide={closeOverlay} centered dialogClassName='img-modal-dialog'>
                    <Modal.Header>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    {selectedImage && (
                        <Modal.Body>
                            <Image src={'./gallery_imgs/' + selectedImage} rounded alt="Selected Image" className="selected-image" />
                        </Modal.Body>
                    )}
                </Modal>

                <Modal show={showT} onHide={handleCloseT} centered dialogClassName='testimonial-modal-dialog' >
                    <Modal.Body style={{ margin: 'auto' }}>
                        <Testimonial name={name} date={t_date} rating={rating} description={review} location={t_location}></Testimonial>
                    </Modal.Body>
                </Modal>


            </Card>
        );
    }

}

// 