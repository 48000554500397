import React, { useState } from 'react'
import { Card, Form, Button, InputGroup, Row, Col } from 'react-bootstrap'
import services from './services.json'
import 'react-phone-number-input/style.css'
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import emailjs from '@emailjs/browser'

const serviceID = process.env.REACT_APP_SERVICE_ID
const templateID = process.env.REACT_APP_TEMPLATE_ID
const emailAPIKEY = process.env.REACT_APP_EMAIL_API_KEY

export default function HorizontalContactForm(props) {
    var name = undefined
  var email = undefined
  const [phone, setPhone] = useState('');
  var servicesSelected = []
  var contactPreference = []
  var description = undefined

  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/;

  function isValidEmail(email) {
    return emailRegex.test(email);
  }

  const changePhoneNumber = (e) => {
    setPhone(e.target.value)
  }

  const handleSubmit = (event) => {
    var errorMessage = ''
    event.preventDefault();
    const form = event.currentTarget;
    name = form.querySelector('#formName').value
    email = form.querySelector('#formEmail').value
    servicesSelected = Array.from(form.querySelectorAll('[name="checkbox-group"]:checked')).map(checkbox => checkbox.id);
    contactPreference = Array.from(form.querySelectorAll('[name="radio-group"]:checked')).map(checkbox => checkbox.id)
    description = form.querySelector('#formDescription').value
    if (name.length === 0) {
      errorMessage += "Please enter a name.\n"
    }
    if (email.length === 0 && phone.length === 0) {
      errorMessage += "Please enter at least one contact method.\n"
    }
    if (phone.length !== 0 && !isPossiblePhoneNumber('+1' + phone)) {
      errorMessage += "A phone number was inputted, but it isn't valid.\n"
    }
    if (email.length !== 0 && !isValidEmail(email)) {
      errorMessage += "An email was inputted, but it isn't valid.\n"
    }

    if (errorMessage === '') {
      props.formError(false)
      props.formSuccess(true)

      var templateParams = {
        f_name: name,
        f_email: email,
        f_phone: phone,
        f_services: servicesSelected.join(", "),
        f_preference: contactPreference[0],
        f_description: description
      }


      emailjs.send(serviceID, templateID, templateParams, emailAPIKEY)
        .then((result) => {
          console.log(result.text)
        }, (error) => {
          console.log(error.text)
        })
    } else {
      props.setError(errorMessage)
      props.formError(true)
      props.formSuccess(false)
    }
  }
  return (
    <Card className='horizontal-contact-form'>
      <Card.Header><strong>Online Contact Form</strong></Card.Header>
      <Form style={{ margin: '10px' }} onSubmit={handleSubmit}>
        <Row>
          <Col><Form.Group className="mb-4" controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" placeholder="Enter your name" />
          </Form.Group>

            <Form.Label>Phone Number</Form.Label>
            <InputGroup className="mb-4">
              <InputGroup.Text>+1</InputGroup.Text>
              <Form.Control type="text" placeholder="Enter your phone number" value={phone} onChange={changePhoneNumber} />
            </InputGroup>

            <Form.Group className="mb-4" controlId="formEmail">
              <Form.Label>E-mail</Form.Label>
              <Form.Control type="text" placeholder="Enter your E-mail address" />
            </Form.Group>
            <Form.Group className="mb-4" controlId="formContactPreference">
              <Form.Label>How would you prefer we reach you?</Form.Label><br />
              <Form.Check type='radio' name='radio-group' id='Phone' label="Phone" inline />
              <Form.Check type='radio' name='radio-group' id='Email' label="Email" inline />
              <Form.Check type='radio' name='radio-group' id='No Preference' label="No Preference" inline defaultChecked />
            </Form.Group>
          </Col>
          <Col><Form.Group className="mb-4" controlId="formService">
            <Form.Label>What services are you interested in?</Form.Label><br />
            {services.map((service, index) => {
              if (service.title !== "Miscellaneous"){
                return (
                  <Form.Check key={index+service.service_image} type='checkbox' name='checkbox-group' id={`${service.title}`} label={service.title} inline />
                )
              } else {
                return(<React.Fragment key={index} />)
              }
            })}
            <Form.Check type='checkbox' name='checkbox-group' id='other' label="Other" inline />
          </Form.Group>

            <Form.Group className="mb-4" controlId="formDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" rows={3} placeholder="Please provide a brief description of what we can help you with." />
            </Form.Group>


          </Col>
        </Row>
        <Button style={{ position: 'absolute', left: '10px', bottom: "10px" }} variant="primary" type="submit" className='me-1' >
          Submit
        </Button>

        <Button style={{ position: 'absolute', left: '90px', bottom: "10px" }} variant="secondary" type="reset" className='me-1' >
          Reset
        </Button>

      </Form>
    </Card>
  )
}
