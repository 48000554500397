import React from 'react'
import { Card, Container, Row, Col, Button } from 'react-bootstrap';
import services from './services.json'
import ContactForm from './ContactForm';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import contact from './contact.json'



export default function HomeContent(props) {


    return (
        <Container className='content-container'>
            <Helmet>
                <title>The Gray Standard - Home - Painting and Handyman Services - Centerville Ohio</title>
                <meta name="description" content="Welcome to our Home page! Learn about our family-owned handyman services and how we enhance homes with expert repairs and renovations." />
            </Helmet>
            <h1 className='heading-text'>The Gray Standard</h1>
           <h2 className='home-subheading-text'> Painting and Handyman Services</h2>
            <img className='home-img' alt='fam' src="./main_imgs/van_crop.jpg"></img>
            <p className='textbox'>We are a family-owned and operated business specializing in providing reliable painting and handyman services to our local community.  We are dedicated to delivering exceptional results that enhance the comfort, functionality, and value of your home.  At The Gray Standard, we take pride in our attention to detail and commitment to customer satisfaction, ensuring that every project is completed to the highest standard - The Gray Standard.  </p> <hr></hr>
            <div className='subheading-container'><div className='subheading-text'>Services We Provide</div></div>


            <p className='textbox'>Looking for reliable painting and handyman services?  Look no further!  At The Gray Standard, we offer a wide array of services to meet your home repair and improvement needs.  With a customer-centric approach, we strive to provide efficient, cost-effective, and timely services that ensure your complete satisfaction.</p> <br></br>

            <Container style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', textAlign: 'center' }}>
                {services.map((service, index) => {
                    if (service.title !== "Miscellaneous"){
                    return (
                        <Card key={index} style={{ width: '19rem', margin: '3px', boxShadow: '1px 1px 5px rgba(16, 16, 16, 0.5)' }}>
                            <Card.Header style={{height: '75px', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '1.3em'}}><strong>{service.title}</strong></Card.Header>
                            <Card.Body style={{ paddingBottom: '40px' }}>
                                <Card.Text>{service.short_description}</Card.Text>
                                <Button className='service-card-button' as={Link} to={`/services#service-card${index}`}>Learn More</Button>
                                {/* <Button className='service-card-button' as={Link} to={`/services?id=${index}`}>Learn More</Button> */}
                            </Card.Body>
                        </Card>
                    )}
                })}
            </Container><br></br>

            <p className='textbox'> If you don't see a particular service listed here, don't hesitate to contact us.  We can almost certainly help you out with your unique requirements and provide a customized solution tailored to your needs.</p>

            <hr></hr>
            <div className='subheading-container'><div className='subheading-text'>Contact</div></div>

            <Container style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', textAlign: 'left', fontSize: '1em' }}>

                <Row>
                    <Col>
                        <Card className='homepage-contact-cards'>
                            <Card.Header><strong>Contact Information</strong></Card.Header>

                            <Card.Body>
                                 <Card.Img variant='top' style={{ width: '100%', margin: 'auto', marginBottom: '14px', borderRadius: '4px'}} src="./main_imgs/Jeff_Alex.jpg"></Card.Img> 
                                <br />
                                <div className='contact-info-font'>
                                <strong>Phone:</strong> {contact.phone} <br />
                                <strong>E-mail:</strong> {contact.email}  <br /><br />
                                <p className='card-textbox'>
                                Our dedicated team is ready to assist you with any inquiries or requests.  To request a quote or to ask general questions, simply reach out to us via phone, email, or through our online contact form.</p></div>
                            </Card.Body>
                        </Card>

                    </Col>

                    <Col>

                        <ContactForm formSuccess={props.formSuccess} formError={props.formError} setError={props.setError}></ContactForm>

                    </Col>
                </Row>

            </Container>
        </Container>
    );
}

