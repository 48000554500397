import React from 'react'
import Container from 'react-bootstrap/Container';
import TestimonialMasonryGrid from './TestimonialMasonryGrid';
import { Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

export default function TestimonialsContent() {

    return (
        <Container className='content-container'>
            <Helmet>
                <title>The Gray Standard - Testimonials - Painting and Handyman Services - Centerville Ohio</title>
                <meta name="description" content="Read what our satisfied clients have to say about The Gray Standard's painting and handyman services. Discover their experiences with our dedicated team, exceptional results, and commitment to customer satisfaction. Get inspired by real testimonials from real customers!" />
            </Helmet>
            <div className='heading-container'><h1 className='heading-text-2'>Testimonials</h1></div>
            <p className='textbox'>
                At The Gray Standard, we pride ourselves in delivering top-notch craftsmanship and unmatched customer service.  Discover the experiences and feedback from our satisfied clients who have entrusted us with their home improvement projects.  Our team has garnered high praise for our professionalism, attention to detail, and commitment to customer satisfaction.
                <br></br> <br></br>
            </p>
            <p className='textbox'>
            Your opinion matters to us! If you enjoyed your experience with us, kindly consider leaving us a Google review. Your feedback not only encourages our team but also aids potential customers in making informed decisions about our services. Thank you for your support!
            </p>
            <br></br>
            <Button href='https://g.page/r/CbK2ULojPicbEBM/review' target='_blank'>Review us on Google</Button><hr></hr>

            <TestimonialMasonryGrid />

        </Container>
    )
}   