import React from 'react'
import { Container, Card, Image } from 'react-bootstrap';
import data from './teamMembers.json'
import { useMediaQuery } from 'react-responsive'
import { Helmet } from 'react-helmet'


export default function AboutUsContent() {
    const isMobile = useMediaQuery({ maxWidth: 1260 })

    const people = data.team

    return (
        <Container className='content-container'>
            <Helmet>
                <title>The Gray Standard - About Us - Painting and Handyman Services - Centerville Ohio</title>
                <meta name="description" content="Learn about The Gray Standard - a family-owned and operated business specializing in providing reliable painting and handyman services to our local community. Discover our commitment to exceptional results and customer satisfaction." />
            </Helmet>
            <div className='heading-container'><h1 className='heading-text'>About Us</h1></div>
            <div className='subheading-container'><h2 className='home-subheading-text'>Our Story</h2></div>
            <p className='textbox-about-us'>
                Believe it or not, this kind of started way back in 1992.  My wife, Pam, and I had been married for one year and she was pregnant with our son, Alex.  We bought our first house to accommodate our expanding family.  After we had moved in, we discovered a leak in the shower of our master bedroom.  We hired a local company to repair it and they informed us that we needed a complete replacement of the shower; consequently, we took out a loan and had the work done.  Sadly, after the job was "completed", the shower still leaked.  Equally troubling was that the company would not stand behind their work.  I remember telling my wife that I could have done this…and so it started.  I would get home improvement books from the library and try to improve our house and was pretty successful doing so.<br></br><br></br>
                A few years later we moved to a new home as we had welcomed our daughter, Kristen, and had outgrown our first house.  Again, I was ALWAYS trying to improve our home.  I soon found that I had a bit of a knack for doing this; and, so did my neighbors, friends, and relatives.  Occasionally, they would need help with something or just look for suggestions on what to do.  Many times, Alex would tag along as he liked to see what was happening.  As I did more and more projects at our home, he became a bigger part of helping; most times willing and other times not so much...teenagers/college students.  Even Kristen would help/get drafted on projects at home.<br></br><br></br>
                By the time both kids had graduated from WSU and had moved out, I had retired from my career after 30 years.  I took a part-time job helping people and did that for six years.  That's when Alex approached me and wanted to know if I would be interested in helping him start a painting and handyman business.  This was something he was always kind of interested in and he decided, to quote Elvis, "It’s now or never." <br></br><br></br>
                We have completed seven jobs so far and everyone has been very complimentary of us.  In fact, five of the clients have already reached back to us to return to do additional work.  We hear the same thing, "We trust you."  This is something that is very important to us.  My Dad always told me growing up that "Anything worth doing is worth doing right."  This is something I taught my children, too.  If you decide to have us out at your home, we will treat the job as if it were at our home.  If it is something we can't do, we will tell you that, too.  We want the job done right.<br></br><br></br>
                Alex and Jeff Gray.

            </p><hr></hr>
            <div className='subheading-container'><div className='subheading-text'>Meet the Team</div></div>
            <Container style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', textAlign: 'left', fontSize: '1em' }}>
                {
                    people.map((p, k) => {
                        if (!isMobile) {
                            if (k === 0) {
                                return (
                                    <Card key={k} style={{ width: '100%', margin: '10px', boxShadow: '1px 1px 5px rgba(16, 16, 16, 0.5)' }}>
                                        <Card.Header><strong>{p.name}</strong></Card.Header>
                                        <Card.Body style={{ display: 'flex', alignItems: 'center' }}>
                                        <Image className='abt-us-img' src={p.photo} ></Image>
                                            <p className='card-textbox' style={{ marginLeft: '20px' }}>{p.description}</p>
                                        </Card.Body>
                                    </Card>
                                )
                            } else if (k === 1) {
                                return (
                                    <Card key={k} style={{ width: '100%', margin: '10px', boxShadow: '1px 1px 5px rgba(16, 16, 16, 0.5)' }}>
                                        <Card.Header><strong>{p.name}</strong></Card.Header>
                                        <Card.Body style={{ display: 'flex', alignItems: 'center', flexDirection: 'row-reverse' }}>
                                            <Image  className='abt-us-img' src={p.photo} ></Image>
                                            <p className='card-textbox' style={{ marginRight: '20px' }}>{p.description}</p>
                                        </Card.Body>
                                    </Card>
                                )
                            } else if (k === 2) {
                                return (<Card key={k} style={{ width: '48%', margin: '10px', boxShadow: '1px 1px 5px rgba(16, 16, 16, 0.5)' }}>
                                <Card.Header><strong>{p.name}</strong></Card.Header>
                                <Card.Body style={{ display: 'flex', alignItems: 'flex-center', }}>
                                <Image className='abt-us-img' src={p.photo} ></Image>
                                  <p className='card-textbox' style={{ marginLeft: '1px', marginTop: '0px', fontSize: '1.1rem', textAlign: 'center' }}>{p.description}</p>
                                </Card.Body>
                              </Card>)
                            } else {
                                return (
                                    <Card key={k} style={{ width: '48%', margin: '10px', boxShadow: '1px 1px 5px rgba(16, 16, 16, 0.5)' }}>
                                        <Card.Header><strong>{p.name}</strong></Card.Header>
                                        <Card.Body style={{ display: 'flex', alignItems: 'flex-center', flexDirection: 'row-reverse' }}>
                                        <Image className='abt-us-img' src={p.photo} ></Image>
                                            <p className='card-textbox' style={{ marginRight: '1px', marginTop: '0px', fontSize: '1.1rem', textAlign: 'center' }}>{p.description}</p>
                                        </Card.Body>
                                    </Card>
                                )
                            }
                        } else {
                            return (<Card key={k} style={{ width: '100%', margin: '10px', boxShadow: '1px 1px 5px rgba(16, 16, 16, 0.5)' }}>
                                <Card.Header><strong>{p.name}</strong></Card.Header>
                                <Card.Body style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Image className='abt-us-img' src={p.photo} ></Image>
                                    <p className='card-textbox' style={{ margin: '5px 10px' }}>{p.description}</p>
                                </Card.Body>
                            </Card>
                            )
                        }
                    })
                }
            </Container>

        </Container>
    );
}   