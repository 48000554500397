import React from 'react';
import { Container } from 'react-bootstrap';
import MasonryGrid from './MasonryGrid';
import { Helmet } from 'react-helmet';

export default function GalleryContent() {

  return (
    <Container className="content-container">
      <Helmet>
        <title>The Gray Standard - Gallery - Painting and Handyman Services - Centerville Ohio</title>
        <meta name="description" content="Browse through The Gray Standard's gallery showcasing our impressive painting and handyman projects. See before-and-after transformations, expert craftsmanship, and satisfied clients. Get inspired for your own home improvement journey!" />
      </Helmet>
      <div className="heading-container">
        <h1 className="heading-text-2">Gallery</h1>
      </div>
      <MasonryGrid />
    </Container>
  );

}
