import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Card } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom'
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import contact from './contact.json'

export default function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setExpanded(false);
  }, [location]);

  function handleClick(link) {
    navigate(link);
    setTimeout(() => {
      window.scrollTo(0,0);
    }, 200)
  }


  return (
    <Navbar expand="xl" className='nav' sticky='top' expanded={expanded} onToggle={() => setExpanded(!expanded)}>
      <Container fluid className='header-width'>
        <Navbar.Brand href="/"><img
          src='/logos/stacked_transparent_100.PNG'
          className='d-inline-block align-top'
          alt='The Gray Standard Logo'
          height='80px'></img></Navbar.Brand>


        <div className='nav-links-container'>
          <Navbar.Collapse id="basic-navbar-nav" className='nav-select'>
            <Nav>
              <Nav.Link 
              onClick={() => handleClick('/')}
              className={location.pathname === '/' ? 'active' : ''}
              >Home</Nav.Link>
              <Nav.Link 
              onClick={() => handleClick('/Services')}
              className={location.pathname === '/Services' ? 'active' : ''}
              >Services</Nav.Link>
              <Nav.Link 
              onClick={() => handleClick('/AboutUs')}
              className={location.pathname === '/AboutUs' ? 'active' : ''}
              >About Us</Nav.Link>
              <Nav.Link 
              onClick={() => handleClick('/Contact')}
              className={location.pathname === '/Contact' ? 'active' : ''}
              >Contact</Nav.Link>
              <Nav.Link 
              onClick={() => handleClick('/Gallery')}
              className={location.pathname === '/Gallery' ? 'active' : ''}
              >Gallery</Nav.Link>
              <Nav.Link 
              onClick={() => handleClick('/Testimonials')}
              className={location.pathname === '/Testimonials' ? 'active' : ''}
              >Testimonials</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </div>
  
          <Card className='contact-card' style={{backgroundColor: '#D1D1BB'}}>
            <Card.Title className='contact-card-title'>Contact Us</Card.Title>
            <Card.Body>
              <strong>Phone:</strong> {contact.phone} <br />
              <strong>E-mail:</strong> {contact.email}
            </Card.Body>
          </Card>


        <div className='header-hide-responsive'>
          <Nav.Link target="_blank" href="https://www.facebook.com/TheGrayStandardLLC">
            <FaFacebook size={25} style={{ color: '#7f807f' }} />
          </Nav.Link>
          <Nav.Link target="_blank" href="https://www.instagram.com/thegraystandardllc/">
            <FaInstagram size={25} style={{ color: '#7f807f' }} />
          </Nav.Link>
        </div>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
      </Container>
    </Navbar>
  );
}

