import React from 'react'
import { Card } from 'react-bootstrap'
import StarRating from './StarRating'


export default function Testimonial({name, date, rating, description, location}) {


  if (rating >= 4) {
    return (
      <Card className='testimonial-card' style={{ margin: '4px' }}>
        <Card.Header>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <StarRating rating={rating}></StarRating>
            <span style={{width: '150px'}}>{date}</span>
          </div>
        </Card.Header>
        <Card.Body>
          <p className='card-textbox'>{description}</p><br></br>
          <p className="testimonial-card-footer">
            <strong>{name}</strong>
            <br></br>
            <em className='smaller-text'>{location}</em>
          </p>
        </Card.Body>
      </Card>
    );
  } else {
    return(<></>)
  }
}
