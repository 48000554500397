import React from 'react'
import { Row, Col } from 'react-bootstrap'
import starFilled from './star-filled.svg'
import starHollow from './star-hollow.svg'

export default function StarRating({rating}) {
  if (rating === 5){
    return (
      <Row className='star-component'>
      <Col xs={1}>
        <img src={starFilled} alt="Star" className="star-image" />
      </Col>
      <Col xs={1}>
        <img src={starFilled} alt="Star" className="star-image" />
      </Col>
      <Col xs={1}>
        <img src={starFilled} alt="Star" className="star-image" />
      </Col>
      <Col xs={1}>
        <img src={starFilled} alt="Star" className="star-image" />
      </Col>
      <Col xs={1}>
        <img src={starFilled} alt="Star" className="star-image" />
      </Col>
    </Row>
    )
  } else {
    return (
      
        <Row className='star-component'>
        <Col xs={1}>
          <img src={starFilled} alt="Star" className="star-image" />
        </Col>
        <Col xs={1}>
          <img src={starFilled} alt="Star" className="star-image" />
        </Col>
        <Col xs={1}>
          <img src={starFilled} alt="Star" className="star-image" />
        </Col>
        <Col xs={1}>
          <img src={starFilled} alt="Star" className="star-image" />
        </Col>
        <Col xs={1}>
          <img src={starHollow} alt="Star" className="star-image" />
        </Col>
      </Row>
    )
  }
}
