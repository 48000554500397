import './App.css';
import React, { useState, useEffect } from 'react';
import Header from './components/Header.js';
import HomeContent from './components/HomeContent'
import ServicesContent from './components/ServicesContent'
import AboutUsContent from './components/AboutUsContent'
import ContactContent from './components/ContactContent'
import GalleryContent from './components/GalleryContent'
import TestimonialsContent from './components/TestimonialsContent'
import Footer from './components/Footer';
import { Toast, ToastContainer } from "react-bootstrap";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  const [showSuccessToast, setShowSuccessToast] = useState(false)
  const [showErrorToast, setShowErrorToast] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const updateSuccess = (value) => {
    setShowSuccessToast(value);
  };

  const updateError = (value) => {
    setShowErrorToast(value);
  };

  const updateErrorMessage = (value) => {
    setErrorMessage(value.split('\n').map((i,k) => {return <p className='toast-closer' key={k}>{i}</p>})); 
  }


  return (
    <div className="App">
      <Router>
        <Header />
        <div style={{ position: 'fixed', zIndex: '50', right: '1rem', top: '10rem', width: '20vw'}}>
          <ToastContainer className='p-2' position='top-end' style={{maxWidth: '75vw'}}>
            {showSuccessToast &&
              <Toast onClose={() => setShowSuccessToast(false)} delay={8000} autohide className={`toast-animation toast-slide-in ${showSuccessToast ? 'show' : ''}`}>
                <Toast.Header
                bg="custom"
                style={{backgroundColor: 'rgba(0,255,0,0.2)', color: 'black'}}>
                  <strong className="me-auto">Success</strong>
                </Toast.Header>
                <Toast.Body>Your inquiry was submitted successfully. We look forward to speaking with you soon!</Toast.Body>
              </Toast>}
            {showErrorToast &&
              <Toast onClose={() => setShowErrorToast(false)} delay={8000} autohide className={`toast-animation toast-slide-in ${showErrorToast ? 'show' : ''}`}>
                <Toast.Header
                bg="custom"
                style={{backgroundColor: 'rgba(255,0,0,0.2)', color: 'black'}}>
                  <strong className="me-auto">Error</strong>
                </Toast.Header>
                <Toast.Body>{errorMessage}</Toast.Body>
              </Toast>
            }
          </ToastContainer>
        </div>
        <Routes>
          <Route path="/" element={
            <HomeContent formSuccess={updateSuccess} formError={updateError} setError={updateErrorMessage}/>
          }></Route>
          <Route path="Services" element={
            <ServicesContent />
          }></Route>
          <Route path="AboutUs" element={
            <AboutUsContent />
          }></Route>
          <Route path="Contact" element={
            <ContactContent formSuccess={updateSuccess} formError={updateError} setError={updateErrorMessage}/>
          }></Route>
          <Route path="Gallery" element={
            <GalleryContent />
          }></Route>
          <Route path="Testimonials" element={
            <TestimonialsContent />
          }></Route>
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
