import React, { useState, useRef, useEffect } from 'react'
import { Container, Card, Col, Row } from 'react-bootstrap';
import ContactForm from './ContactForm';
import HorizontalContactForm from './HorizontalContactForm';
import { Helmet } from 'react-helmet';
import contact from './contact.json'

export default function ContactContent(props) {
    const containerRef = useRef(null)
    const [cutOff, setCutOff] = useState(false)

    useEffect(() => {
        if (containerRef.current.offsetWidth <= 1025) {
            setCutOff(true)
        } else {
            setCutOff(false)
        }
    }, [])


    if (!cutOff) {
        return (
            <Container className='content-container' ref={containerRef}>
                <Helmet>
                    <title>The Gray Standard - Contact - Painting and Handyman Services - Centerville Ohio</title>
                    <meta name="description" content="Contact The Gray Standard for reliable painting and handyman services. Reach out to our dedicated team through phone, email, or our online contact form. We are ready to assist you with any inquiries or requests!" />
                </Helmet>
                <div className='heading-container'><h1 className='heading-text'>Contact</h1></div>

                <p className='textbox'>
                    Don't hesitate to get in touch with us for any inquiries, pricing information, or to schedule an appointment.  We take great pride in delivering exceptional customer service and ensuring your satisfaction. <br></br><br></br>

                    You can reach us by phone, email, or by filling out the contact form below.  Let us know how we can assist you and we will get back to you promptly.  Our friendly staff is available to answer your questions and guide you through the process.  We look forward to working with you and helping you improve the quality of your home!
                </p><hr></hr>

                <Container style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', textAlign: 'left', fontSize: '1em' }}>
                    <Row>
                        <Col>
                            <Card className='contact-page-cards' style={{ height: '10em', marginBottom: '10px', textAlign: 'center' }}>
                                <Card.Header><strong>Contact Information</strong></Card.Header>
                                <Card.Body>
                                    <div className='contact-page-font'>
                                        <strong>Phone:</strong> {contact.phone}<br />
                                        <strong>E-mail:</strong> {contact.email} <br /><br /> </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <br></br>

                    <Row>
                        <Col>
                            <HorizontalContactForm formSuccess={props.formSuccess} formError={props.formError} setError={props.setError}></HorizontalContactForm>
                        </Col>
                    </Row>

                </Container>


            </Container>
        );
    } else {
        return (
            <Container className='content-container' ref={containerRef}>
                <Helmet>
                    <title>The Gray Standard - Contact - Painting and Handyman Services - Centerville Ohio</title>
                    <meta name="description" content="Contact The Gray Standard for reliable painting and handyman services. Reach out to our dedicated team through phone, email, or our online contact form. We are ready to assist you with any inquiries or requests!" />
                </Helmet>
                <div className='heading-container'><h1 className='heading-text'>Contact</h1></div>
                <p className='textbox'>
                    Don't hesitate to get in touch with us for any inquiries, pricing information, or to schedule an appointment.  We take great pride in delivering exceptional customer service and ensuring your satisfaction. <br></br><br></br>

                    You can reach us by phone, email, or by filling out the contact form below.  Let us know how we can assist you and we will get back to you promptly.  Our friendly staff is available to answer your questions and guide you through the process.  We look forward to working with you and helping you improve the quality of your home!
                </p><hr></hr>

                <Container style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', textAlign: 'left', fontSize: '1em' }}>
                    <Row>
                        <Col>
                            <Card className='contact-box' style={{ height: '10em', marginBottom: '10px', textAlign: 'center' }}>
                                <Card.Header><strong>Contact Information</strong></Card.Header>
                                <Card.Body>
                                    <div className='contact-page-font'>
                                        <strong>Phone:</strong> {contact.phone}<br />
                                        <strong>E-mail:</strong> {contact.email}  <br /><br /> </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <br></br>
                    <Row>
                        <Col>
                            <ContactForm formSuccess={props.formSuccess} formError={props.formError} setError={props.setError}></ContactForm>
                        </Col>
                    </Row>

                </Container>

            </Container>
        );
    }
}   