import React from 'react';
import { Navbar, Row, Col } from 'react-bootstrap';

export default function Footer() {
  return (
    <Navbar expand="lg" className='footer'>
      {/* <div className='mx-auto'>The Gray Standard &copy; {new Date().getFullYear()} </div><br></br><div className='footer-quote'>"We do the small jobs no one wants to!"</div> */}
      <Row className='mx-auto'>
        <Col>The Gray Standard &copy; {new Date().getFullYear()}<br></br>
        <em><div className='footer-text-size'>"Anything worth doing is worth doing right."</div></em></Col>
      </Row>
    </Navbar>
  );
}