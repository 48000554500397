import React, { useRef, useEffect, useState } from 'react'
import { Container, Row, Col, Image, Card } from 'react-bootstrap';
import services from './services.json'
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet'


export default function ServicesContent() {
    const containerRef = useRef(null)
    const [cutOff, setCutOff] = useState(false)
    const [selectedCard, setSelectedCard] = useState(null)
    const location = useLocation();
    const url = location.pathname + location.hash

    useEffect(() => {
        if (url === "/Services") {
            return
        }
        var num = url.match(/\d+$/)[0]
        setSelectedCard(parseInt(num, 10)) 
    }, [location])

    useEffect(() => {
        const timer = setTimeout(() => {
          setSelectedCard(null);
        }, 1500);
    
        return () => {
          clearTimeout(timer); 
        };
      }, [selectedCard]);


    useEffect(() => {
        const { hash } = window.location;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                const yOffset = -200;
                const y = element.getBoundingClientRect().top + window.scrollY +  yOffset
                window.scrollTo({top: y, behavior: 'smooth'})
            } else {
                window.scrollTo({ top: 0, behavior: 'smooth'})
            }
        }
    }, [])

    useEffect(() => {
        if (containerRef.current.offsetWidth <= 1140) {
            setCutOff(true)
        } else {
            setCutOff(false)
        }
    }, [])

    // One ServiceSlice per Row
    if (cutOff) {
        return (
            <Container className='content-container' ref={containerRef}>
                <Helmet>
                    <title>The Gray Standard - Services - Painting and Handyman Services - Centerville Ohio</title>
                    <meta name="description" content="Explore our comprehensive range of painting and handyman services. From interior painting to home repairs, our skilled team is dedicated to delivering top-notch results. Discover how we can enhance your home today!" />
                </Helmet>
                <div className='heading-container'><h1 className='heading-text'>Services</h1></div>
                <p className='textbox'>
                    We offer a range of expert solutions tailored to meet your specific needs.  As a family-owned company, we understand the importance of quality craftsmanship and personalized service, striving to exceed your expectations in every project we undertake.  From general repairs and maintenance to specialized tasks like assembling pre-built furniture and more, our team is here to enhance your living spaces with professional expertise and a commitment to excellence.
                </p><hr></hr>

                <Container style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', textAlign: 'left' }}>
                    {
                        services.map((s, k) => {
                            if (s.title !== "Miscellaneous"){
                                if (k % 2 === 0) {
                                    return (
                                        <Card className={`${selectedCard === k ? 'service-card-highlight fade-out': 'service-card'}`} id={"service-card"+k} key={k}>
                                            <Card.Header>{s.title}</Card.Header>
                                            <Card.Body>
                                                <Row style={{ height: '100%' }}>
                                                    <Col style={{ borderRight: '1px solid #969696' }}> <Image className='service-card-image' src={s.service_image} /></Col>
                                                    <Col><p className='card-textbox'>{s.description}</p></Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    )
                                } else {
                                    return (
                                        <Card className={`${selectedCard === k ? 'service-card-highlight fade-out': 'service-card'}`} id={"service-card"+k} key={k}>
                                            <Card.Header>{s.title}</Card.Header>
                                            <Card.Body>
                                                <Row style={{ height: '100%' }}>
                                                    <Col style={{ borderRight: '1px solid #969696' }}><p className='card-textbox'>{s.description}</p></Col>
                                                    <Col><Image className='service-card-image' src={s.service_image} /></Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    )
                                }
                            } else {
                                return(<></>)
                            }
                        })
                    }
                </Container>

            </Container>
        );
    } else { //Two ServiceSlices per row
        return (
            <Container className='content-container' ref={containerRef}>
                <Helmet>
                    <title>The Gray Standard - Services - Painting and Handyman Services - Centerville Ohio</title>
                    <meta name="description" content="Explore our comprehensive range of painting and handyman services. From interior painting to home repairs, our skilled team is dedicated to delivering top-notch results. Discover how we can enhance your home today!" />
                </Helmet>
                <div className='heading-container'><h1 className='heading-text'>Services</h1></div>
                <p className='textbox'>
                    We offer a range of expert solutions tailored to meet your specific needs.  As a family-owned company, we understand the importance of quality craftsmanship and personalized service, striving to exceed your expectations in every project we undertake.  From general repairs and maintenance to specialized tasks like assembling pre-built furniture and more, our team is here to enhance your living spaces with professional expertise and a commitment to excellence.
                </p><hr></hr>

                <Container style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', textAlign: 'left' }}>
                    {
                        services.map((s, k) => {
                            if (s.title !== "Miscellaneous") {
                                if (k % 4 < 2) {
                                    return (
                                        <Card className={`${selectedCard === k ? 'service-card-highlight fade-out': 'service-card'}`} id={"service-card"+k} key={k}>
                                            <Card.Header>{s.title}</Card.Header>
                                            <Card.Body>
                                                <Row style={{ height: '100%' }}>
                                                    <Col style={{ borderRight: '1px solid #969696' }}> <Image className='service-card-image' src={s.service_image} /></Col>
                                                    <Col><p className='card-textbox'>{s.description}</p></Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    )
                                } else {
                                    return (
                                        <Card className={`${selectedCard === k ? 'service-card-highlight fade-out': 'service-card'}`} id={"service-card"+k} key={k}>
                                            <Card.Header>{s.title}</Card.Header>
                                            <Card.Body>
                                                <Row style={{ height: '100%' }}>
                                                    <Col style={{ borderRight: '1px solid #969696' }}><p className='card-textbox'>{s.description}</p></Col>
                                                    <Col><Image className='service-card-image' src={s.service_image} /></Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    )
                                }
                            } else {
                                return(<React.Fragment key={k}/>)
                            }
                        })
                    }
                </Container>

            </Container>
        );
    }
}   