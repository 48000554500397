import React from 'react';
import { Container, Row } from 'react-bootstrap' ;
import '../Gallery.css';
import testimonials from './testimonials.json'
import Testimonial from './Testimonial';
import Masonry from "react-masonry-css"

const TestimonialMasonryGrid = () => {

    const breakpointColumnsObj = {
      default: 3,
      1415: 2,
      1000: 1
    };
  
    return (
      <Container> 
        <Row>
          <Masonry
          breakpointCols={breakpointColumnsObj}
          className='my-masonry-grid'
          columnClassName='my-masonry-grid_column'>
           {
                    testimonials.map((t,k) => {
                        return(
                            <Testimonial key={k} name={t.name} date={t.date} rating={t.rating} description={t.review} location={t.location}></Testimonial>
                        )
                    })
                }
          </Masonry>
        </Row>
      </Container>
    );
    
  
  };
  
  export default TestimonialMasonryGrid
